
import { Component, Vue } from 'vue-property-decorator';
import { DeepReadonly } from 'ts-essentials';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import FullScreenForm from '@/components/FullScreenForm.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import { Environments, RouteNames } from '@/constants';
import { ApiPromotionFormData, Project } from '@/types/projects.types';
import { addAnalyticsRouteParams, ClickData } from '@/analytics';
import GAnalytics from '@/components/GAnalytics';
import { openSnackbar } from '@/utils/components';
import { EnumsModule } from '@/store/modules/enums.module';
import GCheckboxGroup from '@/components/gsk-components/GskCheckboxGroup.vue';
import { FormWizardStep, SelectOption } from '@/components/form/form.types';
import FormWizard from '@/components/form/FormWizard.vue';
import {
  makePromotionFormWizardStep,
  normalizeUrlPath,
  preparePromotionFormData,
} from '@/views/api-registration/registration.helpers';
import { AppEnum } from '@/types/enum.types';
import PreventBack from '@/components/form/PreventBack.vue';
import { ProjectEnvironmentPromotion } from '@/api/projects.api';
import { validateProxyPath, validateResourceName } from '@/api/registration.api';
import { FieldMap } from '@/types/registration.types';
import PromoteProd from '@/views/PromoteToProd.vue';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';
import { v4 as uuid } from 'uuid';

type PromotionFormWizardStep = FormWizardStep<string, { serviceId: number }>;

@Component({
  components: {
    FullScreenForm,
    GButton,
    GTextfield,
    GAnalytics,
    GCheckboxGroup,
    FormWizard,
    PreventBack,
    PromoteProd,
  },
})
export default class PromoteProjectView extends Vue {
  private loading = true;
  private preventExitDisabled = true;
  public formWizardKey = uuid();
  get analyticsData(): ClickData {
    return {
      clickTarget: 'promote-to-prod-form-submit',
      projectName: this.projectName,
      projectId: this.project.projectId,
    };
  }

  get projectIds() {
    return {
      projectId: +this.$route.params.id,
      projectEnvId: +this.$route.params.env,
    };
  }

  get projectEnv() {
    return ProjectDetailsModule.envs[this.projectIds.projectId]?.[this.projectIds.projectEnvId];
  }

  get envEnum(): AppEnum | undefined {
    const envId =
      ProjectDetailsModule.projectEnvsByProjectEnvId[this.projectIds.projectEnvId]?.environmentId;
    return EnumsModule.enumsById.environment[envId];
  }

  get allServices() {
    return this.projectEnv?.registrations?.services?.flat() ?? [];
  }

  regs: string[] = [];
  get registrationOptions(): SelectOption[] {
    if (!FeatureFlagsModule.registrationEnabled) {
      return [];
    }
    return this.allServices
      .filter(service => service.canPromote)
      .map(service => {
        return {
          value: service.serviceId.toString(),
          label: `${service.resourceName} v${service.version}`,
        };
      });
  }

  get hasRegistrationOptions(): boolean {
    return !!this.registrationOptions.length;
  }

  get servicesById() {
    return keyBy(this.allServices, service => service.serviceId);
  }

  validateProxyPath(value: string): Promise<string> {
    const targetEnv = this.isDev ? 'QA' : 'PROD';
    return validateProxyPath(normalizeUrlPath(value, true)).then(r =>
      r.data?.includes(targetEnv) ? '' : 'This proxy path is already in use by another API',
    );
  }

  validateResourceName(value: string): Promise<string> {
    const targetEnv = this.isDev ? 'QA' : 'PROD';
    return validateResourceName(value, targetEnv).then(r =>
      r.data ? '' : 'This registration name is already in use by another project',
    );
  }

  get currentEnv() {
    return ProjectDetailsModule.envs[parseInt(this.$route.params.id)][parseInt(this.$route.params.env)];
  }

  apiPromotionSteps: PromotionFormWizardStep[] = [];
  stepsTmp: PromotionFormWizardStep[] = [];
  constructFormSteps() {
    if (this.regs.length && FeatureFlagsModule.registrationEnabled) {
      const tmp = keyBy(this.stepsTmp, s => s.key);
      const newSteps = this.regs.map(serviceId => {
        return makePromotionFormWizardStep(
          this.currentEnv,
          this.servicesById[serviceId],
          this.nextEnvName,
          this.validateProxyPath,
          this.validateResourceName,
          this.isDev,
        );
      });
      for (const step of newSteps) {
        const old = tmp[step.key];
        if (old) {
          step.fields = merge(step.fields, old.fields);
        }
      }

      this.apiPromotionSteps = newSteps;
      this.formWizardKey = uuid();
    } else {
      // user selected no items
      this.formDone();
    }
  }

  get servicesFormData(): ApiPromotionFormData[] {
    return this.apiPromotionSteps.map(step => {
      const fields = keyBy(step.fields, f => f.key);
      return preparePromotionFormData(fields as FieldMap, step.data?.serviceId ?? -1);
    });
  }

  get nextEnvName() {
    if (this.isDev) {
      return EnumsModule.enums.environment.QA.name;
    }
    return EnumsModule.enums.environment.PROD.name;
  }

  get isDev() {
    return this.envEnum?.mnemonic === 'DEV';
  }

  get isQa() {
    return this.envEnum?.mnemonic === 'QA';
  }

  showProd = false;
  formDone() {
    if (this.isDev) {
      this.promote();
    } else {
      this.showProd = true;
      // going to prod, show the form for the rest
    }
  }

  prodBack() {
    if (this.hasRegistrationOptions) {
      this.showProd = false;
    } else {
      this.$router.replace(this.closeRoute);
    }
  }

  promote() {
    if (this.isDev) {
      this.handlePromotion(
        ProjectDetailsModule.promoteEnvironment({
          projectEnvId: this.projectIds.projectEnvId,
          body: { services: this.servicesFormData },
          srcEnv: Environments.Dev,
        }),
      );
    }
  }

  handlePromotion(p: Promise<ProjectEnvironmentPromotion>) {
    this.loading = true;
    return p
      .then(res => {
        this.preventExitDisabled = true;
        this.$router.replace({
          name: RouteNames.ProjectEnvDetails,
          params: {
            env: res.projectEnvironmentId.toString(),
            id: this.projectIds.projectId.toString(),
            section: 'auth',
          },
        });
      })
      .catch(() => {
        openSnackbar.call(this, 'Error: Could not promote environment', { type: 'error' });
      })
      .finally(() => (this.loading = false));
  }

  goBackToList() {
    this.stepsTmp = this.apiPromotionSteps;
    this.apiPromotionSteps = [];
  }

  get closeRoute() {
    return {
      name: RouteNames.ProjectEnvDetails,
      params: addAnalyticsRouteParams(
        {
          id: this.projectIds.projectId.toString(),
          env: this.projectIds.projectEnvId.toString(),
        },
        {
          projectName: this.projectName,
          projectId: this.project.projectId,
        },
      ),
    };
  }

  get project(): DeepReadonly<Project> {
    return ProjectDetailsModule.projectDetails;
  }

  get projectName(): string {
    return this.project.projectName;
  }

  get projectDescription(): string {
    return this.project.projectDescription;
  }

  async created() {
    const { projectId, projectEnvId } = this.projectIds;
    const fromEnv = this.$store.state?.route?.from?.name === RouteNames.ProjectEnvDetails;
    if (!fromEnv) {
      // data needed
      // - project
      const projP = ProjectDetailsModule.getProject(projectId);
      // - project env: status, registrations
      const regsP = ProjectDetailsModule.getProjectEnvironmentPart({
        projectEnvId,
        projectId,
        partName: 'registrations',
      });
      const statusP = ProjectDetailsModule.getProjectEnvironmentPart({
        projectEnvId,
        projectId,
        partName: 'status',
      });

      // load up the data,  check if we need to load before doing it
      try {
        await Promise.all([projP, regsP, statusP]);
      } catch (e) {
        openSnackbar.call(this, 'Server Error: Could not load data', { type: 'error' });
        this.preventExitDisabled = true;
        return this.$router.replace(this.closeRoute);
      }
    }

    // when should this route be disabled?
    // if initial data request fails (handled above)
    // if project env status is not provisioned
    const env = ProjectDetailsModule.envs[projectId][projectEnvId];
    // const status = EnumsModule.enumsById.status[env.status.statusId];
    if (env.status.statusId !== EnumsModule.enums.status.PROVISIONED.id) {
      openSnackbar.call(this, 'You can only promote a project environment when it is provisioned', {
        type: 'warning',
      });
      this.preventExitDisabled = true;
      return this.$router.replace(this.closeRoute);
    }

    if (this.isQa && !this.hasRegistrationOptions) {
      this.showProd = true;
    }

    this.loading = false;

    console.log({ hasRegistrationOptions: this.hasRegistrationOptions, showProd: this.showProd })
  }
}
